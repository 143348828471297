import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";

const NewsPage = () => (
  <Layout>
    <Seo title="News—January 2013" />
	<NewsHeader />
    <h2>January 2013</h2>




	<h3 className="blogdate">
	Wednesday, January 09, 2013
	</h3>

	<h4 className="blogitemtitle">
	Wellington residency and a video
	</h4>
	   <div className="blogitembody">
	   <p>
	Hi all, just a quick snippet of words by my typey fingers to tell you
	that Peter, Brendan and I (<i>JR and the Rec Devs</i>) are playing
	Thursdays at Daddy O's on Manners Street (used to be Manners Mall) -
	next to Abrakebabra. That's happening this January for certain so come
	on down! Check Twitter for start times on the day. Actually, Peter will
	alternate weeks; he's with us this week and off the next, and so forth.
	</p>
	<p>
	Also, here's our latest video for your viewing and sharing pleasure:
	</p>
	<ul>
	<li>
	<a href="http://www.youtube.com/watch?v=j8lOvtC7rUU"><i>My Ticket Home</i></a>
	</li>
	</ul>
	   </div>
	   	   <div className="blogitemfooter">
	   <p>posted by Jesse @ 21:30 +1300</p>
	   </div>






	<p>
		<Link to="/news/">Go back to the news index page</Link>
	</p>
  </Layout>
);

export default NewsPage;
